@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300&display=swap');

/*Base colors*/
:root{
    --primary:#7cc5fe;
    --google_blue: #4285F4;
    --google_red: #de5246;
    --secondary: #f1c40f;
    --highlights: #00a651;
    --text_color: #03045e;
    --error: #E76F51;
    --background: #ffffff;
    --heading-fontSize: 2rem;
    --small-text-color: #999;
    --heading-text-color: #0a1d37;
  
  
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #00a651;
    --toastify-color-success: #3498db;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #E76F51;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  
    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);
  
    --toastify-toast-width: 320px;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: 'Poppins', sans-serif;
  
    --toastify-text-color-light: #03045e;
    --toastify-text-color-dark: #7cc5fe;
}

/* reset browser default */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.App {
    margin: 0 auto;
    background-color: var(--background);
}


/*Base Styles and Titles*/
body {
    font-family: 'Poppins', sans-serif;
    color: var(--primary);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    padding: 0;
    margin: 0;
}

p {
    color: var(--small-text-color);
    font-size: 1rem;
}

h1,
h2 {
    font-size: var(--heading-fontSize);
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: unset;
}