
/* upload form styles */
.upload {
    width: 100%;
    padding: 8rem 2rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

}

.uploading {
    display: flex;
    flex-flow: column nowrap;
    width: 60%;
    max-width: 100%;
    justify-content: center;
    padding: 5rem;
}

.uploading__progress{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.upload_title {
    font-size: 1.6rem;
    font-weight: bold;
    color: var(--primary);
}

.upload__form {
    display: flex;
    flex-flow: column nowrap;
    width: 60%;
    max-width: 100%;
    margin: 30px auto 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: var(--primary);
    border-radius: 10px;
    color: var(--background);
}

.upload__btn input{
    height: 0;
    width: 0;
    opacity: 0;

}

.upload__btn {
    display: flex;
    border: 0px solid var(--secondary);
    border-radius: 50%;
    margin: 5px auto;
    line-height: 30px;
    color: var(--secondary);
    font-weight: bold;
    font-size: 24px;
    justify-content: center;
    width: fit-content;
    width: -moz-fit-content
  }

.upload__btn:hover{
    background: var(--secondary);
    color: var(--background);
    cursor: pointer;
}

.upload__output{
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    max-height: 20%;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 5px;
    outline: none;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: .4rem;
    color: var(--background);
}

.delete__selection {
    display: flex;
    cursor: pointer;
}

.lordicon__check {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
}

.test {
    border-radius: 10px;
    background-color: var(--primary);
    justify-content: center;
    align-items: center;
}

.error{
    color: var(--error);
}

.input__field {
    display: flex;
    width: 100%;
    height: auto;
    flex-flow: column nowrap;
}

.input__field input, textarea {
    width: 100%;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 1.2rem;
    font-weight: 400;
    padding: 10px;
    background: rgba(255, 255, 255, 0.1);
    color: var(--background);
    letter-spacing: 1px;
}

.input__field label {
    color: var(--background);
    margin: 10px 0;
    display: block;
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    padding-top: 2rem;
}

.submit__btn {
    border: none;
    outline: none;
    border-radius: 10px;
    width: 100%;
    padding: 15px 0;
    color: var(--background);
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.1rem;
    background: var(--highlights);
    cursor: pointer;
    margin-top: 3rem;
}

.submit__btn:hover{
    cursor: pointer;
    background: var(--secondary);
}
  
/* progress bar styles */
.progress__bar{
    height: 5px;
    background: var(--secondary);
    margin-top: 20px;
}